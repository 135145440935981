import React from 'react';
import { Heading, Center, Box } from '@chakra-ui/react';
import Link from 'next/link';

const Index = () => (
  <Center h="100vh" w="100vw">
    <Box>
      <Heading size="4xl" color="forest.600" py="4">
        404
      </Heading>
      <Heading size="lg" color="forest.700">
        You seem to be lost.
      </Heading>
      <Heading size="lg" color="forest.700">
        <Link href="/">
          <a>Would you like to head back?</a>
        </Link>
      </Heading>
    </Box>
  </Center>
);

export default Index;
